<template>
  <div class="main-layout" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div class="content">
      <div class="center">
        <p class="userExperience">
          {{ $t("Install Noorplay App now") }}
        </p>
        <p class="install">
          {{ $t("enjoy the best user experience") }}
        </p>
        <p class="download">{{ $t("Download Now") }}</p>
        <div class="android" v-if="!isIOS">
          <ul>
            <li class="app-links">
              <a href="https://noorplay.app.link" target="_blank">
                <img src="@/assets/icons/google-play.png" alt />
              </a>
            </li>
          </ul>
          <ul>
            <li class="app-links-huawei">
              <a href="https://noorplay1.dre.agconnect.link/F8e9" target="_blank">
                <img src="@/assets/icons/huawei.png" alt="" />
              </a>
            </li>
          </ul>

          <!-- <a href="https://noorplay-landing-page.web.app/settings" target="_blank">Already have our app ?</a> -->
        </div>
        <!-- Ios section -->
        <div class="ios" v-if="isIOS">
          <ul>
            <li class="app-links">
              <a href="https://noorplay.app.link" target="_blank">
                <img src="@/assets/icons/appstore.png" alt />
              </a>
            </li>
          </ul>
          <p class="Or">{{ $t("Or") }}</p>
          <p class="subscribe">
            {{ $t("Subscribe using the mobile website") }}
          </p>
          <button class="subsbribePlans" @click="subscribePlans">
            {{ $t("Subscribe Plans") }}
          </button>
        </div>
        <div v-if="this.$i18n.locale === 'ara'" class="arabicCTAB" :dir="localDisplayLang === 'ara' ? 'ltr' : 'ltr'">
          <button @click="switchToInit">متابعة</button>&nbsp;&nbsp;<span>للاستمرار في تصفح الموقع اضغط على</span>
        </div>
        <button class="stayOnThisWebsite" @click="switchToInit" v-if="this.$i18n.locale === 'eng'">
          {{ $t("Stay on this website") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
export default {
  data() {
    return {
      localDisplayLang: "eng",
      isIOS: false,
      isSubscribe: false,
      subscribepayload: {},
      isPayment: false,
      subscriberDetailsMobileView: "",
    };
  },

  computed: {
    ...mapGetters(["appConfig", "subscriberId"]),
  },

  created() {
    localStorage.setItem("showSubscriptionPlans", false);
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    //console.log("local display language", this.localDisplayLang);

    // CheckForIOS is called to check if it is IOS mobile  browser
    this.checkForIOS();
  },
  mounted() {
    //Have to remove few header contents when in mobile view , so calling this event
    eventBus.$emit("hideHeaderOptions");
  },
  methods: {
    checkForIOS() {
      console.log("check for IOS");
      const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);
      if (
        /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
        (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
      ) {
        // true for mobile device
        this.isIOS = true;
        //console.log("it is ios");
      } else {
        this.isIOS = false;
        //console.log("it is not ios");
      }
    },
    switchToInit() {
      //console.log("im switching to init");
      localStorage.setItem("isMobile", false);
      let payload = false;
      eventBus.$emit("triggerInitView", payload);
    },
    subscribePlans() {
      //console.log("subscribe popup");
      this.subscriberDetailsMobileView = JSON.parse(localStorage.getItem("subscriberDetails"));
      // console.log("sub det from local", this.subscriberDetailsMobileView);
      if (
        this.subscriberDetailsMobileView &&
        this.subscriberDetailsMobileView.data &&
        this.subscriberDetailsMobileView.data.subscriberid
      ) {
        //console.log("show subscriptions popup==========");
        eventBus.$emit("triggerInitView", payload);
        let payload = { listType: "onlySubscribe" };
        eventBus.$emit("subscribePopup", payload);
      } else {
        //console.log("show auth popup========");
        let payload = {
          state: true,
          formType: "lookup",
          isMobileWeb: true,
        };
        eventBus.$emit("authPopup", payload);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.main-layout {
  display: flex;
  .content {
    margin-top: 50px;
    background-image: url("~@/assets/icons/mob_View_Background.png");
    @media only screen and (max-width: 768px) {
      height: 768px;
      // position: fixed;
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media only screen and (min-width: 768px) {
      height: 1336px;
      // position: fixed;
      background-size: cover;
      background-repeat: no-repeat;
    }

    width: 100%;
    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      .userExperience {
        margin-top: 50px;
        top: 109px;
        left: 42px;
        width: 326px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffb200;
				// background: linear-gradient(to right, #7730C1,#7730C1, #2633D3);;
				// 	-webkit-background-clip: text;
				// 	-webkit-text-fill-color: transparent;
        opacity: 1;
        font-size: 22px;
      }
      .install {
        margin-top: 10px;
        margin-left: 5px;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        font-size: 20px;
      }
      .download {
        margin-top: 40px;
        left: 112px;
        width: 266px;
        height: 32px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        font-size: 20px;
        opacity: 0.8;
      }
    }

    .app-links {
      img {
        top: 240px;
        left: 109px;
        width: 172px;
        height: 67px;
        // opacity: 0.7;
        // &:hover {
        //   opacity: 0.9;
        // }
      }
    }
    .app-links-huawei {
      img {
        top: 326px;
        left: 120px;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
        width: 151px;
        height: 45px;
        // opacity: 0.7;
        // &:hover {
        //   opacity: 0.9;
        // }
      }
    }
    .stayOnThisWebsite {
      top: 374px;
      margin-top: 35px;
      left: 119px;
      color: #560C1D;
      opacity: 1;
      background: none;
      text-align: left;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 0px;
      border: none;
      text-align: center;
      outline: none;
      font-size: 12px;
    }
    .Or {
      top: 326px;
      left: 187px;
      width: 20px;
      height: 18px;
      font-size: 15px;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .subscribe {
      margin-top: 10px;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .subsbribePlans {
      margin-top: 15px;
      width: 149px;
      height: 40px;
      border-radius: 15px;
      border: 1px solid $btn-clr;
      background: $btn-clr-new;
      color: #ffffff;
      opacity: 1;
      outline: none;
    }

    .ios {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .android {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .arabicCTAB {
      top: 374px;
      margin-top: 35px;
      left: 119px;
      opacity: 1;
      background: none;
      text-align: left;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      letter-spacing: 0px;
      border: none;
      text-align: center;
      outline: none;
      font-size: 12px;
      button {
        background: $btn-clr-new;
        color: #ffffff;
        border: none;
      }
      span {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        letter-spacing: 0px;
        border: none;
        text-align: center;
        outline: none;
        font-size: 12px;
        color: #ffffff;
      }
    }
    @media only screen and (max-height: 480px) {
      margin-top: 40px;
    }
  }
}
</style>
